<template>
  <div id="ProfilePage" :class="education_show == true ? 'hidePageScroll' : ''">
    <Sidebar />
    <div class="ProfileContent">
      <p class="PageHeader">{{ project.name }}</p>
      <div class="ProfileBlocks">
        <div class="profileBlock BlockColumn1">
          <ProfileProjectCard
            class="ShowOnEducation"
            :Project="project"
            :GuestMode="true"
            :UseAutoWidth="true"
          />
          <div class="TextBlock"><p>О заказчике</p></div>
          <div class="TextBlock"><p>Комментарий куратора</p></div>
        </div>
        <div class="profileBlock BlockColumn2">
          <Competence />
        </div>
      </div>
    </div>

    <Stage v-show="isModalVisible" @close="closeModal" />

    <div
      class="EducationScreenBlocker"
      v-show="education_show"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    />
    <div class="EducationScreenWrapper" v-show="education_show">
      <div class="stepWrapper step1"></div>
    </div>
  </div>
</template>

<style scoped>
.ShowOnEducation {
  position: relative;

  z-index: 800;
}

.hidePageScroll {
  overflow: hidden;
}

.EducationScreenBlocker {
  position: absolute;

  width: 100%;
  height: 100vh;

  z-index: 500;

  background-color: rgba(0, 0, 0, 0.75);
}
.EducationScreenWrapper {
  position: absolute;

  width: 100%;
  height: 100vh;

  z-index: 1000;
}

#ProfilePage {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

#ProfilePage > .ProfileContent {
  position: relative;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  margin: 23px 32px 0% 21px;
  padding: 0%;
}

#ProfilePage > .ProfileContent > .ProfileBlocks {
  position: relative;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  margin: 0%;
  padding: 0%;
}

#ProfilePage > .ProfileContent > .ProfileBlocks > .profileBlock {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

#ProfilePage > .ProfileContent > .PageHeader {
  position: relative;
  width: 100%;

  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

.BlockColumn1,
.BlockColumn2 {
  margin-right: 32px;
  flex-shrink: 0;
}
.BlockColumn1 {
  width: fit-content;
  margin-bottom: 40px;
}
.BlockColumn2 {
  width: fit-content;
}
.BlockColumn2 > * {
  width: fit-content !important;
}

.BlockColumn3 {
  width: fit-content;
}

.TextBlock {
  position: relative;

  width: 100%;
  height: auto;

  margin: 0% 0% 0% 30px;
  padding: 28px 32px;

  background: rgba(32, 30, 72, 0.7);
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: 20px;
}

.TextBlock > p {
  position: relative;
  margin-bottom: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #e8e7ec;
}
</style>

<script>
import TasksList from "@/components/Profile/TasksList.vue";
import NewsFeed from "@/components/Profile/NewsFeed.vue";
import Sidebar from "@/components/Profile/Sidebar.vue";
import Competence from "@/components/Profile/CompetenceBlock.vue";
import ProfileProjectCard from "@/components/Profile/BetaProfileProjectCard.vue";
import ProfileProgressCard from "@/components/Profile/BetaProfileProgressCard.vue";
import Stage from "@/components/Profile/StagesCompetence.vue";

export default {
  data() {
    return {
      isModalVisible: false,
      education_show: false,

      project: {
        name: "Сервис для бронирования общих ресурсов",
        stage: "Подготовка проекта: Набор команды",
        stageTimer: "До завершения этапа : 23 дня 16:05:22",
        difficult: 3,
        customer: "ГК Форус",
        desc:
          "Разработать сервис для удобного бронирования общих ресурсов: один фотограф на организацию, один кабинет в общем пользовании и т.д. Календарная форма для бронирования, уведомление всех участников и проверка на повторное бронирование - важные части системы.",
        directions: [
          {
            text: "Web-разработка",
            BGColor: "#1DD185",
          },
          {
            text: "Дизайн",
            BGColor: "#1DC6D1",
          },
          {
            text: "Аналитика",
            BGColor: "#D434E2",
          },
        ],
        tags: [
          {
            text: "Программирование",
            difficult: 3,
          },
          {
            text: "Маркетинг",
            difficult: 1,
          },
          {
            text: "1С",
            difficult: 1,
          },
        ],
        unfinishedTasks: [{ title: "1" }, { title: "2" }],
      },
    };
  },
  name: "BetaHome",
  components: {
    Sidebar,
    Competence,
    ProfileProjectCard,
    ProfileProgressCard,
    NewsFeed,
    TasksList,
    Stage,
  },
  computed: {},
  mounted() {},
  methods: {
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>
