<template>
  <div class="modal-backdrop">
    <div class="modal">
      <span class="modal-head">
        Этапы юнита
        <button type="button" class="btn-close" @click="close"></button>
      </span>
      <p class="p-header">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
      <div class="btn-stage">
        <button
          v-for="(Stage, index) in defaultStages"
          :key="Stage"
          :class="activeButton == Stage.name ? 'activeButton' : ''"
          @click="activeButton = Stage.name"
        >
          {{ `Этап ${index + 1}: ${Stage.name}` }}
        </button>
      </div>

      <div class="StageContent">
        <template v-for="(Stage, index) in defaultStages">
          <div
            :class="`Stage${index + 1}`"
            v-if="activeButton == Stage.name"
            :key="Stage"
          >
            <p class="StageContentHeader">
              Этап {{ index + 1 }}: {{ Stage.name }}
            </p>
            <p class="StageContentDescription">
              Расскажите о своей концепции проекта, какую идею вы хотите
              реализовать через выбранный проект.
            </p>
            <div class="StageSteps">
              <div class="StageStep">
                <div
                  v-for="(Step, index) in getStepsForThisStage"
                  :class="
                    `StageContainer ${
                      stepIsSuccess(Step.id) == false
                        ? 'StepAwaiting'
                        : 'StepSuccess'
                    }`
                  "
                  :key="Step"
                >
                  <p class="StageStepHeader">
                    Шаг {{ index + 1 }}: {{ Step.name }}
                  </p>
                  <p class="StageStepDesc">
                    {{ Step.description }}
                  </p>
                  <!-- <Button ButtonText="Перейти к заполнению" /> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/CustomElements/ButtonGradientBorder.vue";

export default {
  props: {
    defaultStages: Array,
    defaultSteps: Array,
    userSteps: Array,
  },
  name: "StagesCompetence",
  computed: {
    getStepsForThisStage() {
      let result = [];

      for (let step of this.defaultSteps) {
        if (step.stage.name == this.activeButton) {
          result.push(step);
        }
      }

      return result;
    },
  },
  watch: {
    userSteps(newQuestion, oldQuestion) {
      if (newQuestion) {
        this.getActiveStage(newQuestion);
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getActiveStage(UserSteps) {
      console.log(UserSteps);

      /* Соотносим этапы и шаги */
      let Stage_Steps = [];
      for (let Step of this.defaultSteps) {
        let stageExist = Stage_Steps.find((obj) => {
          return obj.id === Step.stage.id;
        });

        if (stageExist == null) {
          Stage_Steps.push({
            id: Step.stage.id,
            name: Step.stage.name,
            steps_id: [Step.id],
          });
        } else {
          stageExist.steps_id.push(Step.id);
        }
      }

      /* Удаляем из этапов шаги, что уже выполнены пользователем */
      for (let userStep of UserSteps) {
        let stageExist = Stage_Steps.find((obj) => {
          return obj.id === userStep.stage_id;
        });

        if (stageExist != null) {
          const index = stageExist.steps_id.indexOf(userStep.id);
          if (index > -1) {
            stageExist.steps_id.splice(index, 1);
          }
        }
      }

      /* Выбираем активный этап, где у нас остались незаконченные шаги */
      for (let Stage of Stage_Steps) {
        if (Stage.steps_id.length != 0) {
          this.activeButton = Stage.name;
          return 0;
        }
      }
    },

    stepIsSuccess(step_id) {
      let stepReady = this.userSteps.find((obj) => {
        return obj.id === step_id;
      });

      return stepReady == null ? false : true;
    },
  },
  data() {
    return {
      activeBlock: [],
      activeButton: "",
    };
  },
  components: { Button },
};
</script>

<style scoped>
.modal-head > .btn-close {
  position: relative;
  width: 19.5px;
  height: 19.5px;

  background-image: url("./../../assets/img/profile/Stages/СloseModal.svg");

  opacity: 1;

  background-position: center;
  background-size: 19.5px;
  background-attachment: inherit;
  background-repeat: no-repeat;
}

.StageStepDesc {
  margin-bottom: 12px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  color: #c8c3de;
}
.StageStepHeader {
  position: relative;
  top: -7px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;

  color: #e8e7ec;

  margin-bottom: 1px;
}

.StageSteps {
  position: relative;
  width: auto;
  height: auto;

  background-image: url("./../../assets/img/profile/Stages/LeftLine.svg");

  background-position: 4.5px 0%;
  background-size: 1px;
  background-attachment: inherit;
  background-repeat: repeat-y;
}

.StageSteps > .StageStep:not(:nth-last-child(1)) {
  padding-bottom: 30px;
}
.StageSteps > .StageStep:nth-child(1) {
  background-position: 4.5px 6px;
}
.StageSteps > .StageStep {
  position: relative;
  width: auto;
  height: auto;
}
.StageSteps > .StageStep > .StageContainer {
  position: relative;
  width: auto;
  height: auto;

  padding-left: 20px;
}
.StageSteps > .StageStep > .StepSuccess {
  background-image: url("./../../assets/img/profile/Stages/GreenDot.svg");

  background-position: 0px 0px;
  background-size: 10px;
  background-attachment: inherit;
  background-repeat: no-repeat;
}
.StageSteps > .StageStep > .StepAwaiting {
  background-image: url("./../../assets/img/profile/Stages/PurpleDot.svg");

  background-position: 0px 0px;
  background-size: 10px;
  background-attachment: inherit;
  background-repeat: no-repeat;
}

.StageContent,
.StageContent > div {
  position: relative;
  width: auto;
  max-width: 858px;
  height: auto;
}
.StageContent > div > .StageContentHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #e8e7ec;
}

.StageContentDescription {
  margin-bottom: 24px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  color: #c8c3de;
}

.modal-backdrop {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(4px);

  z-index: 200;
}

.modal {
  background: #1d1a42;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 1027px;
  height: auto;
  border-radius: 20px;
  padding: 48px;
  position: static;
  margin: auto;

  background-image: url("./../../assets/img/profile/Stages/UpperBG.svg"),
    url("./../../assets/img/profile/Stages/BottomBG.svg");

  background-position: 0px 0px, 0px 100%;
  background-size: 100% auto;
  background-attachment: inherit;
  background-repeat: no-repeat;
}

.modal-head {
  padding: 0px;
  display: flex;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  color: #e8e7ec;
}
.p-header {
  position: relative;
  max-width: 858px;

  margin: 12px 0px 0px 0px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  color: #c8c3de;
}
.btn-stage button.activeButton {
  padding: 6px 16px;

  background-color: #413e74;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  color: #ffffff;
  border: none;
}

.btn-stage button {
  display: left;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 15px;
  background: none;
  border-radius: 4px;
  border: 1px solid #413e74;
  margin-right: 8px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  color: #e8e7ec;
}
.btn-stage {
  margin-top: 32px;
}

.btn-close {
  border: none;
  font-size: 24px;
  padding: 3px;
  color: grey;
  background: transparent;
  margin: auto 0px auto auto;
  line-height: normal;
}

.StageContent {
  position: relative;
  margin-top: 24px;
}
</style>
